module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/favicon-32x32.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d1010f0f56c7f0c2c27ec060ed3493a7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://live-milk-experiment.pantheonsite.io/graphql","schema":{"perPage":20,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000,"requestConcurrency":15,"previewRequestConcurrency":5},"html":{"imageMaxWidth":2000,"fallbackImageMaxWidth":2000,"useGatsbyImage":true,"imageQuality":90,"createStaticFiles":true,"generateWebpImages":false},"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-X37VDV5JQ8"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
